<template lang="pug">
  tr(:key="`${item.id}-row`" :active="isSelected" :class="{'updated' : item.animation}" class="cbr-acc-row")
    td.cbr-acc-select
      v-checkbox(
        hide-details
        :value="isSelected"
        :key="index"
        @change="select"
      ).mt-0
    td.cbr-acc-username {{ item.username }}
    td.cbr-acc-budget {{ item.budget }}
    td.cbr-acc-status
      cbr-acc-import(
        :ID="item.id"
        :status="cbrAccStatusText"
        :color="cbrAccStatusColor"
        :loading="cbrAccStatusLoading"
      )
    td.cbr-acc-reserved {{ item.reserved_candidates || '-' }}
    td.cbr-acc-accepted {{ item.accepted_candidates || '-' }}
    td.cbr-acc-new {{ item.new_candidates || '-' }}
    td.cbr-acc-rate {{ `${item.success_rate}%` || '-' }}
    td.cbr-acc-course-locations(@click="openLocationChoose").pointer
      span {{ item.courseLocations | renderCities(cities) }}
      template(v-if="item.courseLocations && !item.courseLocations.length")
        v-btn(x-small outlined).btn-common add

    td.cbr-acc-products(@click="openLocationChoose").pointer
      span(v-for="(product, index) in item.products" :key="product") {{ product }}
        template(v-if="item.products.length - 1 !== index") ,&nbsp;
      template(v-if="item.products && !item.products.length")
        v-btn(x-small outlined).btn-common add

    td.cbr-acc-export {{ item.formattedSlotsExportTime }}

    courseLocationProvider(ref="courseLocationModal" :ID="item.id")
      template(v-slot:default="props")
        courseLocationModal(
          v-model="props.showing"
          @input="hideLocationChoose"
          :processing="props.processing"
          :model="item"
          @submit="props.update"
        )
</template>

<script>
import { mapGetters } from 'vuex';
import { CBR_ACC_SLOTS_LOADING_STATUS, CBR_ACC_SLOTS_LOADING_STATUS_COLOR } from "../core/cbrAcc-const"

export default {
  props: {
    item: {
      required: true,
      type: Object
    },
    select: Function,
    isSelected: {
      type: Boolean,
      default: false
    },
    index: Number
  },

  computed: {
    ...mapGetters({
      cities: 'crmCourseCities/items',
      products: 'crmProductNames/items'
    }),

    cbrAccStatusColor() {
      if (this.item.slotsLoadingStatus === CBR_ACC_SLOTS_LOADING_STATUS.IN_PROGRESS)
        return CBR_ACC_SLOTS_LOADING_STATUS_COLOR.IN_PROGRESS
      if (this.item.slotsLoadingStatus === CBR_ACC_SLOTS_LOADING_STATUS.FAILED)
        return CBR_ACC_SLOTS_LOADING_STATUS_COLOR.FAILED
      return CBR_ACC_SLOTS_LOADING_STATUS_COLOR.INACTIVE
    },

    cbrAccStatusLoading() {
      return this.item.slotsLoadingStatus === CBR_ACC_SLOTS_LOADING_STATUS.IN_PROGRESS;
    },

    cbrAccStatusText() {
      if (this.item.slotsLoadingStatus === CBR_ACC_SLOTS_LOADING_STATUS.INACTIVE) return 'Success'
      return this.item.slotsLoadingStatus
    }
  },

  filters: {
    renderCities(ids, cities) {
      if (!cities) return
      return ids.map(ID => {
        let city = cities.find(city => city.ID === ID)
        if (city) return city.name
      }).join(', ')
    }
  },

  methods: {
    openLocationChoose() {
      this.$refs.courseLocationModal.toggle()
    },

    hideLocationChoose(isShowing) {
      if (isShowing) return
      this.$refs.courseLocationModal.toggle()
    }
  },

  components: {
    cbrAccImport: () => import('./CbrAccImport.vue'),
    courseLocationProvider: () => import('./CbrAccCourseLocationProvider.vue'),
    courseLocationModal: () => import('./cbrAccLocationChoose.vue')
  }
}
</script>

<style lang="scss" scoped>
.contents:nth-child(2n) tr {
  background: #F4F7F9;
}

.cbr-acc-row {
  .cbr-acc- {
    &select div {
      padding-left: 12px;
    }
    &reserved {
      width: 145px;
      max-width: 145px;
    }
    &accepted {
      width: 145px;
      max-width: 145px;
    }
    &new {
      width: 145px;
      max-width: 145px;
    }
    &rate {
      width: 100px;
      max-width: 100px;
    }
  }
}
</style>